<template>
  <v-app>
    <component
      v-if="layout"
      v-bind:is="layout">
      <router-view v-bind:key="viewKey" />
    </component>
    <v-snackbar
      dark
      v-bind:bottom="snackbarAlign.bottom"
      v-bind:right="snackbarAlign.right"
      v-bind:centered="snackbarAlign.centered"
      v-model="snackbar"
      v-bind:color="snackbarColor"
      v-bind:timeout="snackbarTimeout"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="snackbar = false"
        >Закрыть</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<script>
import snackbarMixin from '@/mixins/snackbarMixin';

/**
 * @description Компонент приложения.
 */
export default {
  mixins: [snackbarMixin],
  components: {
    AdminPanelLayout: () => import('@/layouts/AdminPanelLayout.vue'),
    AuthLayout: () => import('@/layouts/AuthLayout.vue'),
  },
  computed: {
    /**
     * @description Идентификатор компонента-макета.
     * @returns {string}
     */
    layout() {
      return this.$route.meta.layout;
    },
    /**
     * @description Ключ отображаемого раздела.
     * @returns {string}
     */
    viewKey() {
      return this.$route.fullPath;
    },
  },
  metaInfo: { title: 'МИС' },
};
</script>
<style lang="scss">
@use "@/scss/commonScss.scss" as *;

html {
   overflow-y: hidden !important;
}

.container-full {
  min-height: 100%;
}
</style>
