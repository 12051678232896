import {
  Either,
  Maybe,
} from 'monet';

/**
 * @description Шлюз тестовых клиник.
 * @param {Axios} axios HTTP клиент.
 * @constructor
 */
export default function TestClinicsGateway(axios) {
  /**
   * @description Получить таблицу тестовых клиник
   *
   * @param page  Страница
   * @param match Строка поиска
   * @return {Promise<Maybe>}
   */
  this.getTestClinicsTable = (page, match = '') => axios
    .get(
      '/api/admin/test-clinics',
      {
        params: {
          match,
          page,
        },
      },
    )
    .then((response) => Maybe.fromEmpty(response.data));

  /**
   * @description Добавить клинику к тестовым.
   *
   * @param clinicId Id Клиники
   *
   * @return {Promise<Maybe>}
   */
  this.addClinicToTest = (clinicId) => axios.post(
    `/api/admin/test-clinics/${clinicId}`,
  ).then(
    (response) => Either.Right(response.data),
    (error) => {
      if (error.isAxiosError && error.response.status === 400) {
        return Either.Left(error.response.data);
      }
      throw error;
    },
  );

  /**
   * @description Удалить клинику из списка тестовых.
   *
   * @param clinicId Id Клиники
   *
   * @return {Promise<Maybe>}
   */
  this.removeClinicFromTest = (clinicId) => axios.delete(
    `/api/admin/test-clinics/${clinicId}`,
  )
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );
}
