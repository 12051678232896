import { Either, Maybe } from 'monet';

/**
 * @description Шлюз СМС.
 * @param {Axios} axios HTTP клиент.
 * @constructor
 */
export default function SmsGateway(axios) {
  /**
   * @description Подключить СМС клинике.
   * @param {string} clinicId Идентификатор клиники.
   * @param {any}    request  Запрос.
   * @returns {Promise<Either<unknown, any>>}
   */
  this.connectClinic = (clinicId, request) => axios
    .post(`/api/admin/sms-clinics/${clinicId}`, request)
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Создать план уведомлений.
   * @param {number} clinicId Идентификатор клиники.
   * @param {any}    request  Запрос.
   * @returns {Promise<Either>}
   */
  this.createClinicPlan = (clinicId, request) => axios
    .post(`/api/admin/sms-clinics/${clinicId}/plans`, request)
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Получить клиники-кандидаты.
   * @param {number} page  Номер страницы.
   * @param {string} match Совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getCandidateClinics = (page, match) => axios
    .get('/api/admin/sms-candidate-clinics', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить планы уведомлений клиники.
   * @param {number} clinicId Идентификатор клиники.
   * @param {string} channel  Канал отправки уведомления.
   * @returns {Promise<Maybe>}
   */
  this.getClinicPlans = (clinicId, channel) => axios
    .get(`/api/admin/sms-clinics/${clinicId}/plans`, { params: { channel } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить клиники.
   * @param {number} page  Номер страницы.
   * @param {string} match Совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getClinics = (page, match = '') => axios
    .get('/api/admin/sms-clinics', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить шаблоны СМС-рассылок для клиники.
   * @returns {Promise<Maybe>}
   */
  this.getDispatchableTemplatesForClinic = () => axios
    .get('/api/admin/sms-clinic-dispatchable-templates')
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить шаблоны СМС-уведомлений.
   * @param {number} page Номер страницы.
   * @param {string} match Совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getTemplates = (page, match = '') => axios
    .get('/api/admin/sms-clinic-templates', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Пополнить СМС-аккаунт клиники.
   * @param {number} clinicId Идентификатор клиники.
   * @param {any}    request  Содержимое запроса.
   * @returns {Promise<Either>}
   */
  this.recharge = (clinicId, request) => axios
    .post(`/api/admin/sms-clinics/${clinicId}/recharges`, request)
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Заменить ключ SMSRU.
   * @param {number} clinicId Идентификатор клиники.
   * @param {any}    request  Содержимое запроса.
   * @returns {Promise<Either>}
   */
  this.replaceSmsruKey = (clinicId, request) => axios
    .put(`/api/admin/sms-clinics/${clinicId}/smsru-key`, request)
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Удалить план СМС-уведомлений клиники.
   * @param {number} clinicId Идентификатор клиники.
   * @param {number} planId   Идентификатор плана.
   * @param {number} channel  Канал отправки.
   * @returns {Promise<Maybe>}
   */
  this.removeClinicPlan = (clinicId, planId, channel) => axios
    .delete(`/api/admin/sms-clinics/${clinicId}/plans/${planId}`, { data: { channel } })
    .then(
      async (response) => Maybe.fromEmpty(response.data),
    );

  /**
   * @description Удалить шаблон СМС-уведомлений.
   * @param {number} templateId Идентификатор шаблона.
   * @returns {Promise<Maybe>}
   */
  this.removeTemplate = (templateId) => axios
    .delete(`/api/admin/sms-clinics-templates/${templateId}`)
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Просмотр шаблона СМС-уведомлений.
   * @param {number} templateId Идентификатор шаблона.
   * @returns {Promise<Maybe>}
   */
  this.viewTemplate = (templateId) => axios
    .get(`/api/admin/sms-clinics-templates/${templateId}`)
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Отключить интеграцию клинике.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.disableIntegration = (clinicId) => axios
    .put(`/api/admin/sms-clinics/${clinicId}/disable`)
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Включить интеграцию клинике.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.enableIntegration = (clinicId) => axios
    .put(`/api/admin/sms-clinics/${clinicId}/enable`)
    .then(async (response) => Maybe.fromEmpty(response.data));
}
