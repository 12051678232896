import { Fail, Success } from 'monet';

/**
 * Релиз
 *
 * @param {string} id            Идентификатор
 * @param {string} dateOfRelease Дата релиза
 * @param {string} countViews    Количество просмотров
 * @constructor
 */
export function Release(
  id,
  dateOfRelease,
  countViews,
) {
  this.dateOfRelease = dateOfRelease;
  this.id = id;
  this.countViews = countViews;
}

/**
 * @description Запрос на получение оценок по обновлениям.
 * @param {number} page
 * @param {string} search
 * @param {string} sortType
 * @param {string} sortDirection
 * @param {string} selectedUpdatedDate
 * @constructor
 */
export function GetUpdateVotesListRequest(
  page = 1,
  search = '',
  sortType = '',
  sortDirection = '',
  selectedUpdatedDate = '',
) {
  this.search = search;
  this.sortType = sortType;
  this.sortDirection = sortDirection;
  this.page = page;
  this.selectedUpdatedDate = selectedUpdatedDate;

  this.toQuery = () => ({
    search_query: this.search,
    sort_type: this.sortType,
    sort_direction: this.sortDirection,
    page: this.page,
    updated_date: this.selectedUpdatedDate,
  });
}

/**
 * @description Шлюз релизов.
 * @param {Axios} axios
 */
export default function ReleaseGateway(axios) {
  /**
   * @description Показать список релизов.
   * @returns {Promise<Array<Release>|Fail>}
   */
  this.viewReleaseList = async () => {
    try {
      const response = await axios.get('/api/admin/releases');
      const releases = response.data.data.map((item) => new Release(
        item.id,
        item.date_of_release,
        item.count_of_views,
      ));
      return Success(releases);
    } catch (err) {
      return Fail(err);
    }
  };
  /**
   * @description Создать релиз.
   *
   * @param {Object} request
   *
   * @return {Promise<Success|Fail>}
   */
  this.createRelease = async (request) => {
    try {
      const response = await axios.post('/api/admin/releases', request);
      return Success(response);
    } catch (err) {
      return Fail(err.response);
    }
  };
  /**
   * @description Удалить релиз.
   *
   * @param {string} id
   *
   * @return {Promise<Success|Fail>}
   */
  this.deleteRelease = async (id) => {
    try {
      const response = await axios.delete(`/api/admin/releases/${id}`);
      return Success(response);
    } catch (err) {
      return Fail(err.data.title);
    }
  };
  /**
   * @description Получить список отзывов по обновлениям
   * @param {GetUpdateVotesListRequest} request
   * @returns {Promise<Success|Fail>}
   */
  this.getUpdatesVotesList = async (request) => {
    try {
      const { data } = await axios.get('/api/admin/update-votes', { params: request.toQuery() });
      const voteList = data.votes_list.map((item) => ({
        name: item.name,
        updatedAt: item.updated_at,
        likeCount: item.like_count,
        dislikeCount: item.dislike_count,
        tags: item.tags,
      }));
      return Success({
        voteList,
        pageCount: data.page_count,
      });
    } catch (err) {
      return Fail(err);
    }
  };
}
