import AdminClassifierGateway from '@/storage/adminGateways/ClassifierGateway';
import AdminConsultationOrderGateway from '@/storage/adminGateways/ConsultationOrderGateway';
import AdminDocumentTemplateGateway from '@/storage/adminGateways/DocumentTemplateGateway';
import AdminEgiszIemkGateway from '@/storage/adminGateways/EgiszIemkGateway';
import AdminGreenApiGateway from '@/storage/adminGateways/GreenApiGateway';
import AdminIntegratedClinicsGateway from '@/storage/adminGateways/IntegratedClinicsGateway';
import AdminMangoOfficeGateway from '@/storage/adminGateways/MangoOfficeGateway';
import AdminNotificationGateway from '@/storage/adminGateways/NotificationGateway';
import AdminOnlineCheckGateway from '@/storage/adminGateways/OnlineCheckGateway';
import AdminSmsGateway from '@/storage/adminGateways/SmsGateway';
import AdminSuggestedIdeaGateway from '@/storage/adminGateways/SuggestedIdeaGateway';
import AdminTreatmentPlanGateway from '@/storage/adminGateways/TreatmentPlanGateway';
import AdminUisGateway from '@/storage/adminGateways/UisGateway';
import AdminUserGateway from '@/storage/adminGateways/UserGateway';
import AdminVisitDiaryGateway from '@/storage/adminGateways/VisitDiaryGateway';
import ImportGateway from '@/storage/adminGateways/ImportGateway';
import SubscribedClinicsGateway from '@/storage/adminGateways/SubscribedClinicsGateway';
import AdminMessageHistory from '@/storage/adminGateways/MessageHistoryGateway';
import MedflexGateway from '@/storage/adminGateways/MedflexGateway';
import UpdateGateway from '@/storage/adminGateways/UpdateGateway';
import TemplateExampleGateway from '@/storage/adminGateways/TemplateExampleGateway';
import AdminEmployeeGateway from '@/storage/adminGateways/EmployeeGateway';
import AdminTestClinicsGateway from '@/storage/adminGateways/TestClinicsGateway';
import DemoClinicsGateway from '@/storage/adminGateways/DemoClinicsGateway';
import ReleaseGateway from '@/storage/adminGateways/ReleaseGateway';

/**
 * @description Плагин хранилища.
 * @param {Axios} axios Экземпляр HTTP клиента.
 * @constructor
 * @augments PluginObject
 */
export default function VueStoragePlugin(axios) {
  const adminClassifierGateway = new AdminClassifierGateway(axios);
  const adminConsultationOrderGateway = new AdminConsultationOrderGateway(axios);
  const adminDocumentTemplateGateway = new AdminDocumentTemplateGateway(axios);
  const adminEgiszIemkGateway = new AdminEgiszIemkGateway(axios);
  const adminUpdateGateway = new UpdateGateway(axios);
  const adminGreenApiGateway = new AdminGreenApiGateway(axios);
  const adminImportGateway = new ImportGateway(axios);
  const adminIntegratedClinicsGateway = new AdminIntegratedClinicsGateway(axios);
  const adminMangoOfficeGateway = new AdminMangoOfficeGateway(axios);
  const adminNotificationGateway = new AdminNotificationGateway(axios);
  const adminOnlineCheckGateway = new AdminOnlineCheckGateway(axios);
  const adminSmsGateway = new AdminSmsGateway(axios);
  const adminSubscribedClinicsGateway = new SubscribedClinicsGateway(axios);
  const adminDemoClinicsGateway = new DemoClinicsGateway(axios);
  const adminReleaseGateway = new ReleaseGateway(axios);
  const adminSuggestedIdeaGateway = new AdminSuggestedIdeaGateway(axios);
  const adminTreatmentPlanGateway = new AdminTreatmentPlanGateway(axios);
  const adminUisGateway = new AdminUisGateway(axios);
  const adminUserGateway = new AdminUserGateway(axios);
  const adminVisitDiaryGateway = new AdminVisitDiaryGateway(axios);
  const adminMessageHistory = new AdminMessageHistory(axios);
  const adminMedflexGateway = new MedflexGateway(axios);
  const adminTemplateExampleGateway = new TemplateExampleGateway(axios);
  const adminEmployeeGateway = new AdminEmployeeGateway(axios);
  const adminTestClinicsGateway = new AdminTestClinicsGateway(axios);
  /**
   * @description Установить расширения прототипа Vue.
   * @param {VueConstructor} Vue Конструктор компонента.
   * @returns {void}
   */
  this.install = (Vue) => {
    Object.defineProperties(
      Vue.prototype,
      {
        $adminClassifierGateway: {
          get() {
            return adminClassifierGateway;
          },
        },
        $adminConsultationOrderGateway: {
          get() {
            return adminConsultationOrderGateway;
          },
        },
        $adminDocumentTemplateGateway: {
          get() {
            return adminDocumentTemplateGateway;
          },
        },
        $adminExampleTemplateGateway: {
          get() {
            return adminTemplateExampleGateway;
          },
        },
        $adminEgiszIemkGateway: {
          get() {
            return adminEgiszIemkGateway;
          },
        },
        $adminGreenApiGateway: {
          get() {
            return adminGreenApiGateway;
          },
        },
        $adminImportGateway: {
          get() {
            return adminImportGateway;
          },
        },
        $adminIntegratedClinicsGateway: {
          get() {
            return adminIntegratedClinicsGateway;
          },
        },
        $adminMangoOfficeGateway: {
          get() {
            return adminMangoOfficeGateway;
          },
        },
        $adminNotificationGateway: {
          get() {
            return adminNotificationGateway;
          },
        },
        $adminOnlineCheckGateway: {
          get() {
            return adminOnlineCheckGateway;
          },
        },
        $adminSmsGateway: {
          get() {
            return adminSmsGateway;
          },
        },
        $adminSubscribedClinicsGateway: {
          get() {
            return adminSubscribedClinicsGateway;
          },
        },
        $adminSuggestedIdeaGateway: {
          get() {
            return adminSuggestedIdeaGateway;
          },
        },
        $adminTreatmentPlanGateway: {
          get() {
            return adminTreatmentPlanGateway;
          },
        },
        $adminUpdateGateway: {
          get() {
            return adminUpdateGateway;
          },
        },
        $adminUisGateway: {
          get() {
            return adminUisGateway;
          },
        },
        $adminUserGateway: {
          get() {
            return adminUserGateway;
          },
        },
        $adminVisitDiaryGateway: {
          get() {
            return adminVisitDiaryGateway;
          },
        },
        $adminMessageHistoryGateway: {
          get() {
            return adminMessageHistory;
          },
        },
        $adminMedflexGateway: {
          get() {
            return adminMedflexGateway;
          },
        },
        $adminEmployeeGateway: {
          get() {
            return adminEmployeeGateway;
          },
        },
        $adminDemoClinicsGateway: {
          get() {
            return adminDemoClinicsGateway;
          },
        },
        $adminReleaseGateway: {
          get() {
            return adminReleaseGateway;
          },
        },
        $adminTestClinicsGateway: {
          get() {
            return adminTestClinicsGateway;
          },
        },
      },
    );
  };
}
