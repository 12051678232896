import {
  Either,
  Fail,
  Maybe,
  Success,
} from 'monet';

/**
 * @description Запрос на редактирование дополнительных полей клиники.
 */
export class EditClinicAddictionFieldsRequest {
  /**
   * @description Конструктор.
   *
   * @param {string} clinicInn
   * @param {string} clinicName
   * @param {string} clinicLegalName
   */
  constructor(
    clinicInn = '',
    clinicName = '',
    clinicLegalName = '',
  ) {
    this.clinicInn = clinicInn;
    this.clinicName = clinicName;
    this.clinicLegalName = clinicLegalName;
  }

  /**
   * @description К запросу.
   * @return {{clinic_legal_name, clinic_name, clinic_inn}}
   */
  toQuery = () => ({
    clinic_name: this.clinicName,
    clinic_inn: this.clinicInn,
    clinic_legal_name: this.clinicLegalName,
  });
}

/**
 * @description Шлюз платных клиник.
 * @param {Axios} axios HTTP клиент.
 * @constructor
 */
export default function SubscribedClinicsGateway(axios) {
  /**
   * @description Присвоить оплаченный тариф для клиники.
   * @param {object} request Данные запроса.
   * @returns {Promise<Either>}
   */
  this.assignTariff = (request) => axios
    .post(`/api/admin/tariffing-clinics/${request.clinic_id}`, request)
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );
  this.extendDemoTariff = (request) => axios
    .post(`/api/admin/extension-demo-tariff-clinics/${request.clinic_id}`, request)
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );
  /**
   * @description Добавить клинику в архив.
   * @param clinicId
   * @returns {Promise<Either>}
   */
  this.addClinicToArchive = (clinicId) => axios
    .post(`/api/admin/archive-clinics/${clinicId}`)
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Снять ограничения доступа с клиники.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either<{title: string},any>>}
   */
  this.cancelRestrictionForClinic = (clinicId) => axios
    .delete(`/api/admin/restricted-access-clinics/${clinicId}`)
    .then((response) => Either.Right(response.data));

  /**
   * @description Снять VIP доступ с клиники.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either<{title: string},any>>}
   */
  this.cancelClinicVipAccess = (clinicId) => axios
    .delete(`/api/admin/vip-access-clinics/${clinicId}`)
    .then((response) => Either.Right(response.data));

  /**
   * @description Удалить клинику из архива.
   * @param clinicId
   * @returns {Promise<Either>}
   */
  this.deleteClinicFromArchive = (clinicId) => axios
    .delete(`/api/admin/archive-clinics/${clinicId}`)
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Присвоить оплаченный тариф для клиники.
   * @param {number} clinicId Данные запроса.
   * @param {object} request Данные запроса.
   * @returns {Promise<Either>}
   */
  this.editTariffComment = (clinicId, request) => axios
    .put(`/api/admin/tariffing-clinics/${clinicId}/comment`, request)
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Присвоить оплаченный тариф для клиники.
   * @param {object} request Данные запроса.
   * @returns {Promise<Either>}
   */
  this.extensionTariff = (request) => axios
    .post(`/api/admin/extension-tariff-clinics/${request.clinic_id}`, request)
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        const makeCauses = (causes) => {
          const failurePairs = (causes || [])
            .map((cause) => [cause.source, cause.details]);
          return failurePairs;
        };
        if (error.isAxiosError && error.response.status === 400) {
          const { title } = error.response.data;
          const causes = makeCauses(error.response.data.causes);
          return Either.left({ causes, title });
        }
        throw error;
      },
    );
  /**
   * @description Редактировать тариф.
   * @param {string} clinicId Идентификатор клиники.
   * @param {object} request  Данные запроса.
   * @returns {Promise<Either<unknown, any>>}
   */
  this.editTariff = (clinicId, request) => axios
    .put(`/api/admin/tariffing-clinics/${clinicId}/edit`, request)
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Получить клиники-кандидаты для подключения подписки.
   * @param {number} page  Номер страницы.
   * @param {string} match Поисковое совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getCandidateClinics = (page, match = '') => axios
    .get(
      '/api/admin/subscribe-candidate-clinics',
      { params: { match, page } },
    )
    .then((response) => Maybe.fromEmpty(response.data));

  /**
   * @description Просмотр владельца клиники
   * @param {Number} clinicId Идентификатор пользователя.
   * @returns {Promise<Success|Fail>}
   */
  this.getClinicOwner = async (clinicId) => {
    try {
      const response = await axios.get(`/api/admin/clinics-with-modules/${clinicId}/owner`);
      return Success(response.data);
    } catch (error) {
      return Fail(error);
    }
  };
  /**
   * @description Получить клиники с модулями
   * @param {number} page  Номер страницы.
   * @param {string} match Поисковое совпадение.
   * @param {Sort} sort  Сортировка.
   * @returns {Promise<Maybe>}
   */
  this.getClinicsWithModules = (page, sort, match = '') => axios
    .get(
      '/api/admin/clinics-with-modules',
      {
        params: {
          match,
          page,
          sort_by_start_at: sort.isSortByStartsAt,
          sort_by_expires_at: sort.isSortByExpiresAt,
          sort_direction: sort.direction,
        },
      },
    )
    .then((response) => Maybe.fromEmpty(response.data));

  /**
   * Получить последний подключенный тариф клиники
   *
   * @param clinicId
   * @returns {Promise<Success|Fail>}
   */
  this.getPreviousTariff = async (clinicId) => {
    try {
      const response = await axios.get(`/api/admin/clinics-with-modules/${clinicId}/tariff`);
      return Success(response.data);
    } catch (error) {
      return Fail(error);
    }
  };

  /**
   * @description Просмотр архивных клиник.
   * @returns {Promise<Either>}
   */
  this.viewArchiveClinics = async () => axios
    .get('/api/admin/archive-clinics')
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Удалить мусорную клинику.
   * @param {string} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.removeJunkClinic = (clinicId) => axios
    .put(`/api/admin/junked-clinics/${clinicId}`)
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Восстановить мусорную клинику.
   * @param {string} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.restoreJunkClinic = (clinicId) => axios
    .delete(`/api/admin/junked-clinics/${clinicId}`)
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        if (error.isAxiosError && error.response.status === 404) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Ограничить доступ для клиники.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either<{title:string},any>>}
   */
  this.restrictAccessForClinic = (clinicId) => axios
    .put(`/api/admin/restricted-access-clinics/${clinicId}`)
    .then((response) => Either.Right(response.data));

  /**
   * @description Установить клинике VIP доступ.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either<{title:string},any>>}
   */
  this.setClinicVIPAccess = (clinicId) => axios
    .put(`/api/admin/vip-access-clinics/${clinicId}`)
    .then((response) => Either.Right(response.data));

  /**
   * @description Редактировать дополнительные поля клиники.
   * @param {EditClinicAddictionFieldsRequest} request
   * @param {string} clinicId
   * @returns {Promise<Either<{title:string},any>>}
   */
  this.editClinicAddictionFields = (request, clinicId) => axios
    .put(`/api/admin/clinics/${clinicId}/addiction-fields`, request.toQuery())
    .then(
      (response) => Either.Right(response.data),
      (error) => {
        const makeCauses = (causes) => {
          const failurePairs = (causes || [])
            .map((cause) => [cause.source, cause.details]);
          return failurePairs;
        };
        if (error.isAxiosError && error.response.status === 400) {
          const { title } = error.response.data;
          const causes = makeCauses(error.response.data.causes);
          return Either.left({ causes, title });
        }
        throw error;
      },
    );
}
